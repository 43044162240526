<template>
  <div class="search-email-tracking-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <ValidationProvider name="SEARCH_EMAIL_TRACKING_HEADER_COMPONENT_FIELD_NAME_VALIDATE_EMAIL" v-slot="{ errors }" rules>
            <CInput
              :label="$t('SEARCH_EMAIL_TRACKING_HEADER_COMPONENT_LABEL_EMAIL')"
              type="text"
              v-model="searchData.email"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{ errors[0] }}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>
        <CCol md="3" sm="12">
          <div role="group" class="form-group form-group">
            <label class>{{$t("SEARCH_EMAIL_TRACKING_HEADER_COMPONENT_LABEL_STATUS")}}</label>
            <select v-model="searchData.status" class="form-control">
              <option value>{{$t("SEARCH_EMAIL_TRACKING_HEADER_COMPONENT_LABEL_OPTION_ALL")}}</option>
              <option v-for="item in statuses" :key="item.value" :value="item.value">{{item.label}}</option>
            </select>
          </div>
        </CCol>
      </CRow>
      <CRow class="mt-2 form-actions">
        <div class="col-md-3 btn-disabled"></div>
        <div class="col-md-3 btn-disabled"></div>
        <CCol md="3" sm="12">
          <CButton
            class="btn btn-reset"
            @click="resetForm()"
          >{{$t("SEARCH_EMAIL_TRACKING_HEADER_COMPONENT_RESET_BUTTON")}}</CButton>
        </CCol>
        <CCol md="3" sm="12">
          <CButton
            class="btn btn-search"
            @click="search()"
          >{{$t("SEARCH_EMAIL_TRACKING_HEADER_COMPONENT_SEARCH_BUTTON")}}</CButton>
        </CCol>
      </CRow>
    </ValidationObserver>
  </div>
</template>
<script>
import sessionStorageHelper from '@/utils/sessionStorage-helper';
export default {
  name: 'TrackingEmailHeader',
  components: {},
  data() {
    return {
      searchData: sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterEmailTrackings) || this.getDefaultValues(),
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
    statuses: {
      type: Array,
      default: () => [],
    },
  },
  async mounted() {
    this.search();
  },
  methods: {
    getDefaultValues() {
      return {
        email: '',
        status: '',
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();
      this.search();
    },
    async search() {
      sessionStorageHelper.setData(sessionStorageHelper.KEYS.FilterEmailTrackings, this.searchData);
      const searchData = {
        ...this.searchData,
      };
      this.onSearch(searchData);
    },
  },
};
</script>

<style lang="scss">
.search-email-tracking-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
        background: #3b4b7e;
      }

      &.btn-reset {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }
}
</style>

