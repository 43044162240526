var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-tracking-table-container"},[_c('CCard',{staticClass:"email-tracking-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage - 1) * _vm.limit))])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,_vm.dateFormat)))])]}},{key:"subject",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("truncate")(item.subject,40))+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_c('StakingBadge',{attrs:{"type":"tracking-email-status","text":item.status}})],1)]}},{key:"read",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.num_of_views > 0)?_c('CIcon',{staticClass:"read-icon",attrs:{"name":"cil-check"}}):_vm._e()],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"width":"50px"}},[_c('button',{staticClass:"btn btn-view-details",on:{"click":function($event){return _vm.viewDetails(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }