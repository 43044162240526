<template>
  <div class="tracking-email-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="12" sm="12">
            <h1>{{ $t('TRACKING_EMAIL_LIST_PAGE_TITLE') }}</h1>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <TrackingEmailHeader
        :onSearch="onSearch"
        :statuses ="statuses" />
        <TrackingEmailListTable :limit="limit" :activePage="activePage" :items="items" :total="total" />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import TrackingEmailHeader from '@/components/TrackingEmails/TrackingEmailHeader';
import TrackingEmailListTable from '@/components/TrackingEmails/TrackingEmailListTable';
import StakingPagination from '@/components/StakingPagination';
import { endpoints, env, Constants } from '@/constants';
import stringHelper from '@/utils/string-helper';
export default {
  name: 'TrackingEmailList',
  components: {
    TrackingEmailHeader,
    TrackingEmailListTable,
    StakingPagination
  },
  data() {
    return {
      items: [],
      platformList: [],
      statuses: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
    };
  },
  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    await Promise.all([this.getEmailTrackingStatuses()]);
    this.isLoading = false;
  },
  methods: {
    async getEmailTrackingStatuses() {
      try {
        const result = await this.$http.get(endpoints.getEmailTrackingStatuses);
        this.statuses = stringHelper.translateTextForDropdownList(result.data, 'TRACKING_EMAIL_STATUS');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('TRACKING_EMAIL_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getEmailTrackings(data) {
      data = data || {};
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          email: filterData.email,
          status: filterData.status,
        };
        const result = await this.$http.get(endpoints.getEmailTrackings, { params });

        this.items = result.data.items || [];
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('TRACKING_EMAIL_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getEmailTrackings();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getEmailTrackings();
    },
    async onSearch(searchData) {
      const data = searchData;
      this.activePage = 1;

      this.getEmailTrackings(data);
    },
  },
};
</script>
<style lang="scss">
.tracking-email-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
