<template>
  <div class="email-tracking-table-container">
    <CCard class="email-tracking-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template ##="{item, index}">
          <td>{{ index + 1 + (activePage - 1) * limit }}</td>
        </template>
        <template #created_at="{item}">
          <td>{{ item.createdAt | moment(dateFormat) }}</td>
        </template>
        <template #subject="{item}">
          <td>
            {{item.subject | truncate(40)}}
          </td>
        </template>
        <template #status="{item}">
          <td style="text-align: center">
            <StakingBadge type="tracking-email-status" :text="item.status" />
          </td>
        </template>
        <template #read="{item}">
          <td>
            <CIcon v-if="item.num_of_views > 0" class="read-icon" name="cil-check" />
          </td>
        </template>
        <template #action="{item}">
          <td width="50px">
            <button class="btn btn-view-details" @click="viewDetails(item)">
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </button>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import StakingBadge from '@/components/StakingBadge';
import { Constants } from '@/constants';
export default {
  name: 'TrackingEmailListTable',
  components: {
    StakingBadge
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        '#',
        {
          key: 'email',
          label: this.$t('EMAIL_TRACKING_LIST_TABLE_COMPONENT_EMAIL_COL'),
          _style: 'min-width: 120px',
        },
        {
          key: 'subject',
          label: this.$t('EMAIL_TRACKING_LIST_TABLE_COMPONENT_SUBJECT_COL'),
          _style: 'min-width":150px',
        },
        {
          key: 'status',
          label: this.$t('EMAIL_TRACKING_LIST_TABLE_COMPONENT_STATUS_COL'),
          _style:'text-align: center'
        },
        {
          key: 'read',
          label: this.$t('EMAIL_TRACKING_LIST_TABLE_COMPONENT_READ_COL'),
        },
        {
          key: 'action',
          label: this.$t('EMAIL_TRACKING_LIST_TABLE_COMPONENT_ACTION_COL'),
          _style: 'text-align:center',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    viewDetails(item) {
      this.$router.push(`tracking-emails/${item.id}/details`);
    },
  },
};
</script>

<style lang="scss">
.email-tracking-table-container {
  .email-tracking-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }
      .read-icon {
        color: #5da30b;
      }
    }
  }
}
</style>
